<template>
  <div class="candidates-container">
    <vs-row
      class="candidates-content"
      vs-align="flex-end"
      vs-type="flex"
      vs-justify="space-between"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-w="12">
        <FilterSideBar> </FilterSideBar>
        <vs-row
          class="candidates-content mt-8"
          vs-align="flex-start"
          vs-type="flex"
          vs-w="12"
        >
          <vs-table :data="contractList" class="w-full custom-centered-table">
            <template slot="thead">
              <vs-th class="custom-th">ID</vs-th>
              <vs-th class="custom-th">Tipo de Contrato</vs-th>
              <vs-th class="custom-th">Nombre del Contrato</vs-th>
              <vs-th class="custom-th">Fecha de Firma</vs-th>
              <vs-th class="custom-th">Link</vs-th>
              <vs-th class="custom-th">Estatus</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="item in data" :key="item.id">
                <vs-td class="custom-td">{{ item.id }}</vs-td>
                <vs-td class="custom-td">{{ item.documentType }}</vs-td>
                <vs-td class="custom-td">{{ item.documentName }}</vs-td>
                <vs-td v-if="!item.signDate" class="custom-td">{{
                  'No se ha firmado aún'
                }}</vs-td>
                <vs-td v-else class="custom-td">{{ item.signDate }}</vs-td>
                <vs-td class="custom-td">
                  <vs-button
                    class="mr-2"
                    color="primary"
                    @click="verContrato(item.document)"
                  >
                    Ver
                  </vs-button>
                </vs-td>
                <vs-td class="custom-td">
                  <template v-if="item.isSigned">
                    <span class="custom-firmado">Firmado</span>
                  </template>
                  <template v-else>
                    <vs-button
                      class="mr-2"
                      color="primary"
                      @click="firmarContrato(item)"
                    >
                      Firmar
                    </vs-button>
                  </template>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            v-model="currentPage"
            class="pagination-container mt-3"
            :total="totalEmployeePages"
          />
        </vs-row>
      </vs-col>
    </vs-row>
    <vue-fab
      v-if="isAdmin"
      size="big"
      icon="add"
      :scroll-auto-hide="false"
      @clickMainBtn="popup = true"
    />
    <vs-popup
      :title="'Agregar nuevo Documento'"
      :active.sync="popup"
      class="popup-container"
    >
      <div class="popup-content">
        <vs-col
          class="mt-2 px-1 col-inputs-full"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="6"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="Nombre del Contrato"
            rules="required"
          >
            <vs-input
              v-model="documentName"
              label-placeholder="Nombre del Contrato"
              val-icon-danger="error"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
            />
          </ValidationProvider>
        </vs-col>

        <!-- Lista Desplegable para Tipo de Documento -->
        <vs-col
          class="mt-2 px-1 col-inputs-full"
          vs-type="flex"
          vs-justify="center"
          vs-align="justify"
          vs-w="6"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="Tipo de Contrato"
            rules="required"
          >
            <vs-select
              v-model="documentType"
              autocomplete
              class="block col-inputs-full"
              label="Tipo de Contrato"
              placeholder="Elija un Tipo de Contrato"
              val-icon-danger="error"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
            >
              <vs-select-item
                v-for="(item, index) in contractCatalog"
                :key="index"
                :value="item.id"
                :text="item.name"
              />
            </vs-select>
          </ValidationProvider>
        </vs-col>

        <!-- Input para subir archivo PDF -->
        <div class="center">
          <vs-upload
            ref="uploadContract"
            action="#"
            accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            :limit="1"
            :show-upload-button="false"
            text="Cargar PDF"
          />
          <div v-if="isUploadingPdf" class="loading-animation">
            Cargando archivo...
          </div>
        </div>
        <!-- Botón Enviar -->
        <vs-button color="success" class="mt-4" @click="uploadDocument()">
          Enviar
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import FilterSideBar from '@/modules/contracts/components/FilterSideBar'
import { mapState, mapActions } from 'vuex'
import utils from '../../../utils/utils'
export default {
  name: 'ContractsListView',
  components: { FilterSideBar },
  data() {
    return {
      currentPage: 1,
      contractList: [],
      initialDate: null,
      totalEmployeePages: 1,
      documentName: null,
      popup: false,
      contractCatalog: [
        { name: 'Contrato de Trabajadores', id: 'Contrato de Trabajadores' },
        {
          name: 'Contrato de Confidencialidad',
          id: 'Contrato de Confidencialidad',
        },
        { name: 'Prestamo', id: 'Prestamo' },
      ],
      documentType: null,
      isAdmin: false,
    }
  },
  computed: {
    ...mapState('auth', { user: 'user' }),
    ...mapState('company', { company: 'company' }),
  },
  mounted() {
    // Llama a la función para obtener los datos de contratos
    this.getInitialData()
  },
  methods: {
    ...mapActions('contracts', {
      getUserContracts: 'getUserContracts',
      savePdfAction: 'savePdfAction',
    }),
    uploadDocument() {
      let formData = new FormData()

      const fileLength = this.$refs['uploadContract']['filesx'].length

      let pdfDocument = this.$refs['uploadContract']['filesx'][fileLength - 1]

      formData.append('document', pdfDocument)
      formData.append('documentName', this.documentName)
      formData.append('documentType', this.documentType)
      this.savePdfAction(formData)
        .then(() => {
          this.$vs.notify({
            title: 'Éxito',
            text: 'Documento guardado correctamente',
            color: 'success',
            position: 'bottom-center',
          })
          this.popup = false
          this.getInitialData()
        })
        .catch((error) => {
          this.$vs.loading.close()
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            color: 'danger',
            title: 'Ocurrió un problema al guardar el Documento',
            text: `${res}`,
            position: 'top-right',
          })
        })
    },

    async getInitialData() {
      const userProfileId = this.user.userprofile.id
      this.isAdmin =
        this.user.userprofile.isCompanyAdmin ||
        this.user.userprofile.isRecruiter
      this.getUserContracts(userProfileId)
        .then((res) => {
          if (this.contractList.length !== 0) {
            this.contractList.length = 0
          }
          this.contractList.push(...res)
        })
        .catch(() => {
          this.$vs.notify({
            color: 'warning',
            title: 'Advertencia',
            text: 'No se han registrado contratos',
            position: 'top-right',
          })
        })
    },
    verContrato(link) {
      window.open(link, '_blank')
    },
    firmarContrato(contract) {
      const userId = this.user.userprofile.id
      this.$router
        .push({
          name: 'ContractsSignature',
          params: {
            contrato: contract,
            userProfileId: userId,
          },
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
      // Function to handle signing a contract
      // Implement the logic to sign a contract here
    },
  },
}
</script>
<style lang="scss">
@import '~@/scss/_variables.scss';

/* Estilos adicionales para centrar la tabla */
.candidates-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Estilos adicionales para aumentar el tamaño de la tabla */
.custom-centered-table {
  width: 80%; /* Cambia el valor para ajustar el tamaño horizontal */
  margin-top: 30px;
}

/* Estilos adicionales para ajustar el tamaño de las columnas */
.custom-td {
  padding-left: 20px; /* Cambia el valor para ajustar el espacio horizontal */
  padding-right: 20px; /* Cambia el valor para ajustar el espacio horizontal */
}

.custom-th {
  padding-left: 20px; /* Cambia el valor para ajustar el espacio horizontal */
  padding-right: 20px; /* Cambia el valor para ajustar el espacio horizontal */
}

/* Estilos para el texto "Firmado" */
.custom-firmado {
  color: #00cc66; /* Verde */
  font-weight: bold;
}
.popup-container {
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
</style>
